import { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // If using React Router

const RedirectPage = () => {
  const navigate = useNavigate(); // If using React Router

  useEffect(() => {
    const redirectToAppStore = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        // Redirect to Google Play Store for Android users
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.Truxcargo&hl=en";
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // Redirect to Apple App Store for iOS users
        window.location.href =
          "https://apps.apple.com/in/app/truxcargo-courier-track/id6505090308";
      } else {
        // Default fallback for unknown devices
        window.location.href = "https://truxcargo.com/truxcargo-application";
      }
    };

    redirectToAppStore();
  }, []);

  return (
    <div>
      <p>Redirecting to the appropriate app store...</p>
    </div>
  );
};

export default RedirectPage;
