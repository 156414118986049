import React, { useEffect, useState } from "react";
import Pagination from "./Pagination";
import Shimmer from "../components/Shimmer";
import { Link as ScrollLink } from "react-scroll";
import { Link, Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import Navigation from "../components/Navigation";
import Form from "react-bootstrap/Form";
import "animate.css";
import axios from "axios";
import { Button } from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const BlogList = ({ updateMetaTags }) => {
  useEffect(() => {
    updateMetaTags(
      "Blogs | Logistics and Courier Services Blog | Truxcargo",
      "Stay ahead in the world of logistics and courier services with Truxcargo's insightful blog. Discover industry trends, expert tips, and updates on our blog.",
      "Indian courier services"
    );
  }, [updateMetaTags]);
  const media = {
    background: `url("/image/blogs/blog_banner.png")`,
    width: "100%",

    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",

    // height: "85vh",
    padding: "7% 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  };
  const [blogCard, setBlogCard] = useState([]);
  const [searchblog, setSearchBlog] = useState("");
  // const [filteredblog, setFilteredBlog] = useState([]);
  const [originalData, setOriginalData] = useState([]); // Store the original data
  const [loading, setLoading] = useState(true);
  const [Error, setError] = useState(false);
  const itemsPerPage = 6; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  const [age, setAge] = React.useState("");
  const handleSortChange = (event) => {
    setAge(event.target.value);
    const selectedValue = event.target.value;

    if (selectedValue === "1") {
      sortDataDescending();
    } else if (selectedValue === "2") {
      sortDataAscending();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BLOG_API_URL}`
        );
        // console.log("blog response",response.data);
        setBlogCard(response.data.data);
        setOriginalData(response.data.data);

        setLoading(false);
      } catch (error) {
        // console.log("error blog cards");
        setLoading(false);
        setError(true);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <Shimmer />;
  }

  if (Error) {
    return <p>Error fetching data.</p>;
  }
  const createTruncatedMarkup = (text, maxWords) => {
    const words = text.trim().split(/\s+/);
    if (words.length <= maxWords) {
      return { __html: text };
    }
    const truncatedText = words.slice(0, maxWords).join(" ") + "...";
    return { __html: truncatedText };
  };

  // function truncateTitle(title, wordCount) {
  //   const words = title.split(" ");
  //   const truncatedTitle = words.slice(0, wordCount).join(" ") + "...";
  //   return truncatedTitle;
  // }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Calculate the middle position of the screen
    const middlePosition = window.innerHeight / 2;

    // Scroll to the middle of the screen
    window.scrollTo(0, middlePosition);
  };

  const totalItems = blogCard.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedBlogCards = blogCard.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // console.log("body render")
  const searchclick = (event) => {
    if (event) {
      event.preventDefault();
    }
    const filteredBlogs = originalData.filter((data) =>
      data.title.toLowerCase().includes(searchblog.toLowerCase())
    );
    setBlogCard(filteredBlogs); // Update filteredBlog with filtered data
  };

  const sortDataDescending = () => {
    const sortedData = [...originalData].sort((a, b) => a.id - b.id);
    setBlogCard(sortedData);
  };
  const sortDataAscending = () => {
    const sortedData = [...originalData].sort((a, b) => b.id - a.id);
    setBlogCard(sortedData);
  };

  // const handleSortChange = (event)=>{
  //   const selectedValue = event.target.value;

  //   if (selectedValue === "1") {
  //     sortDataDescending()
  //   }
  //   else if (selectedValue === "2") {
  //     sortDataAscending()
  //   }
  // }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // Trigger the search when Enter is pressed
      searchclick();
    }
  };

  return (
    <>
      <Navigation />
      <section className="media_section" style={media}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 step-part">
              <div className="left-side primum-steps">
                <h4>Blogs</h4>
                <p className="text-white">
                  Explore what’s the latest and trending in the realm of
                  logistics!
                </p>
                <ScrollLink to="blog_all">
                  <button className="btn btn-outline-light ">Explore</button>
                </ScrollLink>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_side">
                <img
                  src="/image/blogs/Group1755.png"
                  alt=""
                  srcSet=""
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="blog_all" className="filter_blogs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-8 col-12">
              <h1>All Blog Listings</h1>
            </div>
            <div className="col-lg-6 col-md-8 col-12">
              <Form>
                <div className="blog-search d-flex ">
                  <input
                    placeholder="search any shipment related topic."
                    type="text"
                    className="form-control main-search w-100"
                    value={searchblog}
                    onChange={(e) => setSearchBlog(e.target.value)}
                    onKeyPress={handleKeyPress}
                    required
                  />
                  <Button
                    type="button"
                    className="button-blog-search"
                    onClick={searchclick}
                  >
                    Search
                  </Button>
                </div>
              </Form>
              <small>
                <b>Search Example:- </b>shipment, logistics, transport, delivery
              </small>
            </div>
            {/* <div className="col-lg-1"></div> */}
            <div className="col-lg-3 col-md-4 col-12">
              <div className="sort-select">
                <FormControl sx={{ minWidth: 150 }} size="small">
                  {/* <InputLabel id="demo-simple-select-helper-label">Sort by</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={age}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    // label="Sort by"
                    onChange={handleSortChange}
                    sx={{
                      ".MuiSelect-select": {
                        background:
                          "linear-gradient(89deg, #7939cc 2.76%, #008 95.54%)",
                        color: "#fff",
                      },
                      ".MuiSelect-icon": {
                        fontSize: "1.5rem",
                        color: "#ffffff",
                        background:
                          "linear-gradient(180deg, #7939CC 0%, #4656A3 100%)",
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                        borderRadius: "2px",
                      },
                    }}
                  >
                    <MenuItem value="">Sort by</MenuItem>
                    <MenuItem value="2">New to Old</MenuItem>
                    <MenuItem value="1">Old to New</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* <select className="form-select" onClick={handleSortChange} >
                <option defaultValue>Sort By</option>

                <option value="1" >Old to New</option>
                <option value="2"  >New to Old</option>
              </select> */}
            </div>
          </div>
        </div>
        <hr />
      </section>
      <section className="blog-section">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-9 col-md-9">
              <div className="row">
                {paginatedBlogCards.length === 0 ? (
                  <p>Blogs not found related to this topics</p>
                ) : (
                  paginatedBlogCards.map((item) => (
                    <div className="col-lg-6 col-md-6" key={item.id}>
                      <div className="blog-card">
                        <div className="blog_main_image">
                          <img
                            src={item.pic}
                            srcSet=""
                            alt=""
                            className="w-100"
                          />
                        </div>
                        <div className="blog-content">
                          <RouterLink to={`/blogPost/${item.id}/${item.title}`}>
                            <h3>{item.title}</h3>
                          </RouterLink>
                          <div
                            className="limit"
                            dangerouslySetInnerHTML={createTruncatedMarkup(
                              item.desc,
                              20
                            )}
                          />
                          <div className="read-blog">
                            <small>
                              {format(new Date(item.date), "MMMM dd, yyyy")}
                            </small>
                            <small>
                              {" "}
                              {item.view} view{" "}
                              <i className="fa-solid fa-eye"></i>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="blog-ads-banner text-end">
                <Link to="https://b2b.truxcargo.com/register/">
                  {" "}
                  <img
                    src="/image/blogs/ads1.jpg"
                    alt=""
                    style={{ width: "100%" }}
                  />
                </Link>
                <Link to="/track">
                  <img
                    src="/image/blogs/ads2.jpg"
                    alt=""
                    style={{ width: "100%" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <BlogDetails topLatestBlogs={topLatestBlogs}/> */}
    </>
  );
};

export default BlogList;
