import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  const error_opps = {
    background: `url("/image/404_new.jpg")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh",
    backgroundRepeat: "no-repeat",
  };
  return (
    <>
      <Link to="/">
        {" "}
        <div style={error_opps}></div>
      </Link>
    </>
  );
};

export default ErrorPage;
